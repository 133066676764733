import { Brand } from "~/features/Products/types";
import NumberBadge from "~/features/Recipes/components/NumberBadge";
import { NutritionInfo, RecipeIngredient } from "~/features/Recipes/types";
import { Lang } from "~/types";

type Props = {
  recipeIngredient: RecipeIngredient;
  lang: Lang;
  nutritionInformations: NutritionInfo[];
  index: number;
  handleEdit: (id: string) => void;
  id: string;
};

const IngredientInfo = ({
  recipeIngredient,
  lang,
  nutritionInformations = [],
  index,
  id,
  handleEdit,
}: Props) => {
  const { ingredient } = recipeIngredient;

  const getNutritionInfo = () => {
    if (!recipeIngredient.nutritionInformationId) return "";
    const match = nutritionInformations?.find(
      (n: NutritionInfo) => n.id === recipeIngredient.nutritionInformationId
    );
    return lang === "en" ? match?.title?.en : match?.title?.de;
  };

  const getBrandName = () => {
    if (!ingredient.brands || ingredient.brands.length === 0) return "";
    const ingBrand = ingredient.brands.find(
      (b: Brand) => b.id === recipeIngredient.ingredientBrandId
    );
    return ingBrand ? ingBrand.name : "";
  };

  const metricQuantity =
    lang === "de"
      ? recipeIngredient.metricQuantity
      : recipeIngredient.imperialQuantity;
  const metricMeasure =
    lang === "de"
      ? recipeIngredient.metricMeasure?.title?.[lang]
      : recipeIngredient.imperialMeasure?.title?.[lang];
  const quantity =
    (metricQuantity ?? 0) <= 1
      ? recipeIngredient?.ingredient?.title?.singular?.[lang]
      : recipeIngredient?.ingredient?.title?.plural?.[lang];

  return (
    <div className="flex items-center gap-3">
      <NumberBadge>{index + 1}</NumberBadge>
      <span
        onClick={() => handleEdit(id)}
        className="cursor-pointer hover:text-primary-100"
      >
        {metricQuantity && Math.round(metricQuantity)} {metricMeasure}
        {quantity} ({ingredient.type}) {getBrandName() && `(${getBrandName()})`}
        {getNutritionInfo() && ` | (${getNutritionInfo()})`}
      </span>
    </div>
  );
};

export default IngredientInfo;
